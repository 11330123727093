<template>
  <div class="space-y-10">
    <asom-card>
      <asom-grid :sm="1" :md="2">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filter.dateRange" />
        </asom-form-field>
        <asom-form-field label="Line" required :state="inputStates('filter.line')" error="Select a line">
          <asom-input-select disabled v-model="filter.line" :options="lineOptions"
            :state="inputStates('filter.line')" />
        </asom-form-field>
        <asom-form-field label="Station" required :state="inputStates('filter.station')" error="Select a station">
          <asom-multi-select v-model="filter.station" :options="stationOptions"
            :state="inputStates('filter.station')" />
        </asom-form-field>
        <asom-form-field label="Order Status">
          <asom-multi-select :objectModelValue="false" v-model="filter.status" :options="orderStatusOptions" />
        </asom-form-field>
      </asom-grid>
      <template #footer>
        <asom-export-to-excel :fileName="`Collection Summary Report`" :fields="tableData.fields"
          :labels="tableData.labels" :data="tableData.data" />
        <asom-button text="Reset Filters" variant="secondary" @click="resetFilters" />
        <asom-button text="Apply" :disabled="isLoading" :is-loading="isLoading" @click="loadData" />
      </template>
    </asom-card>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table v-else :data="tableData.data" :columns="tableData.fields" :labels="tableData.labels">
      <template v-slot:header_id>Cash Box/Bag Id</template>
    </asom-client-table>
  </div>
</template>
<script>
import inputStates from "@/mixins/inputStates";
import { displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import moment from 'moment';
import { CompareResult } from "../../../../constants/APIEnums/inventoryEnums";
import { getSccfCashSep } from '../../../../services/cashManagement.service';

export default {
  data() {
    return {
      error: null,
      isLoading: false,
      filter: this.createInitFilters(),
      list: []
    }
  },
  mixins: [inputStates],
  mounted() {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      stationOptions: "smrtResource/stationOptions",
    }),
    orderStatusOptions() {
      return [
        {
          label: CompareResult.NIL.NAME,
          value: CompareResult.NIL.VALUE,
        },
        {
          label: CompareResult.ALIGN.NAME,
          value: CompareResult.ALIGN.VALUE,
        },
        {
          label: CompareResult.DISCREPANT.NAME,
          value: CompareResult.DISCREPANT.VALUE,
        }
      ];
    },
    tableData() {
      return {
        labels: ['Date', 'Station', 'Certis Report', 'SeP Report','Asoms', 'Status'],
        fields: ['collectionDate', 'staitonName', 'verifiedAmount', 'cashReturned', 'sccfOutGoingAmount', 'status'],
        data: this.list.map(item => ({
          ...item,
          collectionDate: displayUtcDateTime(item.collectionDate, 'DD/MM/YYYY'),
          verifiedAmount: item.verifiedAmount || item.verifiedAmount === 0 ? item.verifiedAmount : 'NA',
          cashReturned: item.cashReturned || item.cashReturned === 0 ? item.cashReturned : 'NA',
          sccfOutGoingAmount: item.sccfOutGoingAmount || item.sccfOutGoingAmount === 0 ? item.sccfOutGoingAmount : 'NA',
          status: this.getTransactionStatus(item.status),
        }))
      }
    }
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.list.length = 0;
      const res = await getSccfCashSep({
        "lineId": this.filter.line.value,
        "stationIds": this.filter.station.map(item => item.value),
        "startDate": displayUtcDateTime(this.filter.dateRange.start, 'MM/DD/YYYY'),
        "endDate": displayUtcDateTime(this.filter.dateRange.end, 'MM/DD/YYYY'),
        "status": this.filter.status
      })

      if (res.success) {
        this.list = res.payload.list;
      } else {
        this.list.length = 0;
      }

      this.isLoading = false;
    },
    getTransactionStatus(status) {
      if (status == CompareResult.NIL.VALUE)
        return CompareResult.NIL.NAME 
      if (status == CompareResult.ALIGN.VALUE)
        return CompareResult.ALIGN.NAME 
      if (status == CompareResult.DISCREPANT.VALUE)
        return CompareResult.DISCREPANT.NAME 
      return '';
    },
    createInitFilters() {
      return {
        station: [],
        line: this.$store.getters["auth/userLineOption"],
        dateRange: {
          start: moment().toDate(),
          end: moment().add(1, 'days').toDate()
        },
        status: []
      }
    },
    resetFilters() {
      this.filter = this.createInitFilters();
    }
  }
}
</script>